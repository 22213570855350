<template>
  <div>
    <!-- Phase Change Confirmation Modal -->
    <b-modal
      id="phase_change_confirmation_modal"
      title="Confirm"
      size="sm"
      no-close-on-backdrop
      no-close-on-esc
      hide-footer
    >
      <div class="text-center">
        <h1>Are you sure?</h1>
        <p
          v-html="
            get_confirmation_message(phase_change_confirmation_modal.new_phase)
          "
        ></p>

        <base-button
          type="danger"
          :pill="true"
          class="my-4 btn-success"
          @click="
            handle_phase_change_confirmation_modal_confirm(
              phase_change_confirmation_modal.new_phase
            )
          "
          >Confirm
        </base-button>

        <base-button
          type="primary"
          :pill="true"
          class="my-4 btn-success"
          @click="$bvModal.hide('phase_change_confirmation_modal')"
          >Cancel
        </base-button>
      </div>
    </b-modal>
    <b-row>
      <b-col>
        <div class="header pb-6 d-flex align-items-center">
          <!-- Mask -->
          <span class="mask bg-white opacity-8"></span>
          <!-- Header container -->
          <b-container fluid class="d-flex">
            <b-row>
              <!-- <b-col sm="12" md="auto" class="pt-1 pb-1 pr-0">
                                <div
                                    @click="$router.back()"
                                    class="icon icon-shape rounded-circle shadow bg-primary text-white"
                                >
                                    <i class="fa fa-arrow-left"></i>
                                </div>
                            </b-col> -->
              <b-col sm="12" md="auto" class="mt-2">
                <h1 class="text-primary" v-if="!hackathon.id">
                  Create Hackathon
                </h1>
                <h1 class="text-primary" v-else>
                  {{ form.hackathon_name }}
                </h1>
              </b-col>
              <b-col v-if="hackathon.phase_display"
                ><badge :rounded="true" size="md" type="info" class="mt-3">
                  {{ hackathon.phase_display }}
                </badge></b-col
              >
              <b-col
                v-if="
                  hackathon.status_display &&
                  hackathon.status_display != hackathon.phase_display
                "
                ><badge :rounded="true" size="md" type="danger" class="mt-3">
                  {{ hackathon.status_display }}
                </badge></b-col
              >
            </b-row>
          </b-container>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-container fluid class="mt--6">
          <b-row>
            <b-col sm="12" xl="8">
              <card header-classes="bg-transparent">
                <b-row>
                  <b-col>
                    <validation-observer
                      v-slot="{ handleSubmit }"
                      ref="formValidator"
                    >
                      <b-form
                        role="form"
                        @submit.prevent="handleSubmit(submit_form)"
                      >
                        <base-input
                          :pill="true"
                          label="Hackathon Name*"
                          class="mb-3"
                          name="Hackathon Name"
                          :rules="{ required: true }"
                          placeholder="Create something hackahton"
                          v-model="form.hackathon_name"
                          :disabled="$apollo.loading"
                        >
                        </base-input>
                        <base-input
                          :pill="true"
                          label="Hackathon short description* "
                          class="mb-3"
                          name="Hackathon Description"
                          placeholder="A one liner that describes your hackathon"
                          v-model="form.hackathon_short_desc"
                          :disabled="$apollo.loading"
                          :rules="{
                            required: true,
                          }"
                        >
                        </base-input>

                        <html-editor
                          :pill="true"
                          label="Hackathon detail description"
                          class="mb-3"
                          name="Detailed Description"
                          v-model="form.hackathon_long_desc"
                          :disabled="$apollo.loading"
                        >
                        </html-editor>

                        <!-- Organization -->
                        <b-row v-if="form.organization.options.length > 1">
                          <b-col>
                            <base-input
                              label="Organization"
                              :rules="{
                                required: true,
                              }"
                              :pill="true"
                              name="Organization"
                              :disabled="$apollo.loading"
                            >
                              <el-select
                                class="form"
                                v-model="form.organization.value"
                                :filterable="true"
                                :disabled="$apollo.loading"
                              >
                                <el-option
                                  v-for="option in form.organization.options"
                                  :key="option.id"
                                  :label="option.name || 'Unnamed Organization'"
                                  :value="option.id"
                                >
                                </el-option>
                              </el-select>
                            </base-input>
                          </b-col>
                        </b-row>

                        <!-- Optional Settings -->
                        <b-row>
                          <b-col>
                            <a
                              v-b-toggle
                              href="#optionalSettings"
                              @click.prevent
                              >Optional Settings
                              <span class="when-opened">
                                <i class="fas fa-chevron-up"></i>
                              </span>
                              <span class="when-closed">
                                <i class="fas fa-chevron-down"></i> </span
                            ></a>

                            <b-collapse id="optionalSettings" role="tabpanel">
                              <!-- Organization -->
                              <b-row
                                v-if="form.organization.options.length <= 1"
                              >
                                <b-col>
                                  <base-input
                                    label="Organization"
                                    :rules="{
                                      required: true,
                                    }"
                                    :pill="true"
                                    name="Organization"
                                    :disabled="$apollo.loading"
                                  >
                                    <el-select
                                      class="form"
                                      v-model="form.organization.value"
                                      :filterable="true"
                                      :disabled="$apollo.loading"
                                    >
                                      <el-option
                                        v-for="option in form.organization
                                          .options"
                                        :key="option.id"
                                        :label="option.name"
                                        :value="option.id"
                                      >
                                      </el-option>
                                    </el-select>
                                  </base-input>
                                </b-col>
                              </b-row>
                              <b-row>
                                <b-col>
                                  <b-form-checkbox
                                    v-model="form.management_team_visibile"
                                  >
                                    <span class="">
                                      The management team names and contact
                                      information is available to participants.
                                    </span>
                                  </b-form-checkbox>
                                </b-col>
                              </b-row>
                            </b-collapse>
                          </b-col>
                        </b-row>
                        <!-- Timeline -->
                        <b-row class="mt-5">
                          <b-col>
                            <b-row>
                              <b-col sm="auto">
                                <h3
                                  class="mb-0"
                                  :class="{
                                    strike: !form.has_goals,
                                  }"
                                >
                                  Hackathon Goals
                                </h3>
                              </b-col>
                              <b-col class="pl-0">
                                <b-form-checkbox
                                  v-model="form.has_goals"
                                ></b-form-checkbox>
                              </b-col>
                            </b-row>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col>
                            <p class="">
                              To organize impactful hackathons, you need goals.
                              Having clear goals of what you want to achieve
                              with your hackathon will guide participants,
                              mentors and judges. When teams submit their work,
                              they will select the goals that their solutions
                              contributes to. We recommend having a minimum of 3
                              goals.
                            </p>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col>
                            <GoalsWidget
                              v-if="hackathon.id && form.has_goals"
                              :hackathon_id="hackathon.id"
                              :has_heading="false"
                              :refresh="goals_widget.refresh"
                            />
                          </b-col>
                        </b-row>
                        <b-row class="mt-5">
                          <b-col>
                            <!-- Timeline -->
                            <h3>Your Hackathon Team</h3>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col>
                            <p class="">
                              When running a hackathon, it's always better to do
                              it with a team. Each hackathon team can consist of
                              "Co-organizers", "Hackathon Heroes" and "Team
                              Mentors".
                            </p>
                          </b-col>
                        </b-row>
                        <b-row class="mt-3">
                          <b-col>
                            <h4 class="mb-0">Co-organizers</h4>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col>
                            <p class="">
                              Co-organizers are people who form part of the
                              "Management" team of the hackathon. Members of the
                              hackathon organizing team can manage attributes of
                              the hackathon.
                            </p>
                            <p class="" v-if="hackathon.id">
                              To manage your organizing team, click
                              <router-link
                                :to="{
                                  path: `/hackathon/${hackathon.id}/organizing-team`,
                                }"
                                >here</router-link
                              >.
                            </p>
                          </b-col>
                        </b-row>

                        <b-row class="mt-3">
                          <b-col>
                            <b-row>
                              <b-col sm="auto">
                                <h4
                                  class="mb-0"
                                  :class="{
                                    strike: !form.has_volunteers,
                                  }"
                                >
                                  Hackathon Heroes
                                </h4>
                              </b-col>
                              <b-col class="pl-0">
                                <b-form-checkbox
                                  v-model="form.has_volunteers"
                                ></b-form-checkbox>
                              </b-col>
                            </b-row>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col>
                            <p class="">
                              Hackathon Heroes are people with a "can-do"
                              attitude that supports the hackathon. They help
                              teams, answer questions, listen to ideas and
                              provide feedback.
                            </p>
                            <p
                              class=""
                              v-if="hackathon.id && form.has_volunteers"
                            >
                              To manage your hackathon heroes, click
                              <router-link
                                :to="{
                                  path: `/hackathon/${hackathon.id}/heroes`,
                                }"
                                >here</router-link
                              >.
                            </p>
                          </b-col>
                        </b-row>

                        <b-row class="mt-3">
                          <b-col>
                            <b-row>
                              <b-col sm="auto">
                                <h4
                                  class="mb-0"
                                  :class="{
                                    strike: !form.has_mentors,
                                  }"
                                >
                                  Mentors
                                </h4>
                              </b-col>
                              <b-col class="pl-0">
                                <b-form-checkbox
                                  v-model="form.has_mentors"
                                ></b-form-checkbox>
                              </b-col>
                            </b-row>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col>
                            <p class="">
                              Mentors are people that will guide, challenge and
                              engage with teams. The mentors are there to help
                              the teams flesh out their ideas and ask difficult
                              questions. Normally Mentors "roam" the hackathon,
                              joining virtual or physical tables to engage with
                              the teams.
                            </p>
                            <p class="" v-if="hackathon.id && form.has_mentors">
                              To manage your mentors, click
                              <router-link
                                :to="{
                                  path: `/hackathon/${hackathon.id}/organizing-team`,
                                }"
                                >here</router-link
                              >.
                            </p>
                          </b-col>
                        </b-row>

                        <b-row class="mt-5">
                          <b-col>
                            <!-- Timeline -->
                            <b-row>
                              <b-col>
                                <h3>Timeline</h3>
                              </b-col>
                            </b-row>
                            <time-line type="one-side">
                              <!-- Registrations open -->
                              <time-line-item badge-type="primary" dot-only>
                                <b-row>
                                  <b-col>
                                    <b-row>
                                      <b-col sm="auto">
                                        <h4 class="mt--1 mb-0">
                                          Registrations open
                                        </h4>
                                      </b-col>
                                      <b-col
                                        class="text-right"
                                        v-if="
                                          phase_allowed_as_next(
                                            'REGISTRATION_OPEN'
                                          )
                                        "
                                      >
                                        <base-button
                                          type="primary"
                                          :pill="true"
                                          size="sm"
                                          @click="
                                            show_phase_change_confirmation_modal(
                                              'REGISTRATION_OPEN'
                                            )
                                          "
                                          >Activate
                                        </base-button>
                                      </b-col>
                                      <b-col
                                        sm="auto"
                                        class="text-right"
                                        v-if="
                                          hackathon.phase == 'REGISTRATION_OPEN'
                                        "
                                      >
                                        <badge
                                          :rounded="true"
                                          size="md"
                                          type="primary"
                                        >
                                          Active
                                        </badge>
                                      </b-col>
                                    </b-row>
                                    <p class="">
                                      This is when registrations open for the
                                      hackathon
                                    </p>
                                  </b-col>
                                </b-row>
                                <b-row>
                                  <b-col sm="12" md="6">
                                    <base-input
                                      label="Who can participate?"
                                      :rules="{
                                        required: true,
                                      }"
                                      :pill="true"
                                      name="Particpants*"
                                      :disabled="$apollo.loading"
                                    >
                                      <el-select
                                        class="form"
                                        v-model="
                                          form.allowed_participants.value
                                        "
                                        :rules="{
                                          required: true,
                                        }"
                                        :default-first-option="true"
                                        :filterable="true"
                                      >
                                        <el-option
                                          v-for="option in form
                                            .allowed_participants.options"
                                          :key="option.id"
                                          :label="option.name"
                                          :value="option.id"
                                        >
                                        </el-option>
                                      </el-select>
                                    </base-input>
                                  </b-col>
                                </b-row>
                              </time-line-item>
                              <!-- Idea submission and team formation -->
                              <time-line-item badge-type="primary" dot-only>
                                <b-row>
                                  <b-col>
                                    <h4 class="mt--1 mb-0">
                                      Idea submission and Team formation
                                    </h4>
                                    <p class="">
                                      Ideas is where everything begins, in this
                                      phase, people will submit ideas and find a
                                      team to work with.
                                      <br />
                                      For the best results, teams of 3 to 5
                                      people is recommended.
                                    </p>
                                  </b-col>
                                  <b-col
                                    class="text-right"
                                    sm="auto"
                                    v-if="
                                      phase_allowed_as_next('IDEA_SUBMISSION')
                                    "
                                  >
                                    <base-button
                                      type="primary"
                                      :pill="true"
                                      @click="
                                        show_phase_change_confirmation_modal(
                                          'IDEA_SUBMISSION'
                                        )
                                      "
                                      size="sm"
                                      >Activate
                                    </base-button>
                                  </b-col>
                                  <b-col
                                    class="text-right"
                                    v-if="hackathon.phase == 'IDEA_SUBMISSION'"
                                    sm="auto"
                                  >
                                    <badge
                                      :rounded="true"
                                      size="md"
                                      type="primary"
                                    >
                                      Active
                                    </badge>
                                  </b-col>
                                </b-row>
                                <b-row>
                                  <b-col>
                                    <b-form-checkbox
                                      v-model="form.ideas_require_approval"
                                    >
                                      <span
                                        class=""
                                        :class="{
                                          strike: !form.ideas_require_approval,
                                        }"
                                      >
                                        Ideas has to be approved before they
                                        become visible to participants.
                                      </span>
                                    </b-form-checkbox>
                                  </b-col>
                                </b-row>
                                <b-row>
                                  <b-col sm="4">
                                    <base-input
                                      label="Maximum team size"
                                      :rules="{
                                        required: true,
                                        numeric: true,
                                        max_value: 10,
                                      }"
                                      :pill="true"
                                      type="number"
                                      name="Max team size"
                                      :disabled="$apollo.loading"
                                      v-model="form.max_team_size"
                                    >
                                    </base-input>
                                  </b-col>
                                </b-row>
                              </time-line-item>
                              <!-- Hackathon Starts -->
                              <time-line-item badge-type="primary" dot-only>
                                <b-row>
                                  <b-col>
                                    <h4 class="mt--1 mb-0">Hackathon Starts</h4>
                                    <p class="">
                                      This is where your hackathon officially
                                      starts. An onboarding presentation of 15
                                      minutes should be given.
                                    </p>
                                  </b-col>
                                </b-row>
                                <b-row>
                                  <b-col sm="12" md="6">
                                    <base-input
                                      :pill="true"
                                      type="datetime-local"
                                      id="start-datetime"
                                      label="Start date*"
                                      v-model="form.start_date"
                                      :rules="{
                                        required: true,
                                      }"
                                      :disabled="$apollo.loading"
                                    />
                                  </b-col>
                                </b-row>

                                <b-row>
                                  <b-col>
                                    <small class="font-weight-bold mt--6 pb--6">
                                      <a
                                        href="javascript:void(0);"
                                        @click="
                                          set_event_future_date_time(
                                            'start_date',
                                            14
                                          )
                                        "
                                        ><i class="far fa-clock"></i>
                                        In ~ 2 weeks
                                      </a>
                                      &nbsp;
                                      <a
                                        href="javascript:void(0);"
                                        @click="
                                          set_event_future_date_time(
                                            'start_date',
                                            28
                                          )
                                        "
                                        ><i class="far fa-clock"></i>
                                        In ~ 4 weeks
                                      </a>
                                      &nbsp;
                                      <a
                                        href="javascript:void(0);"
                                        @click="
                                          set_event_future_date_time(
                                            'start_date',
                                            60
                                          )
                                        "
                                        ><i class="far fa-clock"></i>
                                        In ~ 2 months
                                      </a>
                                    </small>
                                  </b-col>
                                </b-row>
                                <b-row>
                                  <b-col>
                                    <b-form-checkbox
                                      v-model="form.has_kickoff_session"
                                    >
                                      <span
                                        class=""
                                        :class="{
                                          strike: !form.has_kickoff_session,
                                        }"
                                      >
                                        Add a hackathon kickoff talk/call to the
                                        program.
                                      </span>
                                    </b-form-checkbox>
                                  </b-col>
                                </b-row>
                              </time-line-item>
                              <!-- Elevator Pitches & mentorship roaming -->
                              <!-- <time-line-item
                                                                badge-type="primary"
                                                                dot-only
                                                            >
                                                                <b-row>
                                                                    <b-col>
                                                                        <h4
                                                                            class="mt--1 mb-0"
                                                                        >
                                                                            Elevator
                                                                            Pitches
                                                                            <b-form-checkbox>
                                                                            </b-form-checkbox>
                                                                        </h4>
                                                                        <p
                                                                            class=""
                                                                        >
                                                                            Elevator
                                                                            pitches are
                                                                            viewed by
                                                                            mentors and
                                                                            other team
                                                                            members
                                                                        </p>
                                                                    </b-col>
                                                                </b-row>
                                                            </time-line-item> -->

                              <!-- Team Submissions -->
                              <time-line-item badge-type="primary" dot-only>
                                <b-row>
                                  <b-col>
                                    <h4 class="mt--1 mb-0">Hacking Ends</h4>
                                    <p class="">
                                      Choose when the participant's time for
                                      hacking ends. This will also be when
                                      submissions are due.
                                    </p>
                                  </b-col>
                                  <b-col
                                    class="text-right"
                                    sm="auto"
                                    v-if="
                                      phase_allowed_as_next('HACKING_ENDED')
                                    "
                                  >
                                    <base-button
                                      type="primary"
                                      :pill="true"
                                      size="sm"
                                      @click="
                                        show_phase_change_confirmation_modal(
                                          'HACKING_ENDED'
                                        )
                                      "
                                      >Activate
                                    </base-button>
                                  </b-col>
                                  <b-col
                                    class="text-right"
                                    v-if="hackathon.phase == 'HACKING_ENDED'"
                                    sm="auto"
                                  >
                                    <badge
                                      :rounded="true"
                                      size="md"
                                      type="primary"
                                    >
                                      Active
                                    </badge>
                                  </b-col>
                                </b-row>
                                <b-row>
                                  <b-col sm="12" md="6">
                                    <base-input
                                      :pill="true"
                                      type="datetime-local"
                                      id="start-datetime"
                                      label="End date*"
                                      v-model="form.end_date"
                                      :rules="{
                                        required: true,
                                      }"
                                      :disabled="$apollo.loading"
                                    />
                                  </b-col>
                                </b-row>
                                <b-row>
                                  <b-col>
                                    <small
                                      class="font-weight-bold mt--6 pb--6"
                                      v-if="form.start_date"
                                    >
                                      <a
                                        href="javascript:void(0);"
                                        @click="set_event_end_date_time(24)"
                                        ><i class="far fa-clock"></i>
                                        Duration: 24 hours
                                      </a>
                                      &nbsp;
                                      <a
                                        href="javascript:void(0);"
                                        @click="set_event_end_date_time(48)"
                                        ><i class="far fa-clock"></i>
                                        Duration: 2 days (48h)
                                      </a>
                                      &nbsp;
                                      <a
                                        href="javascript:void(0);"
                                        @click="set_event_end_date_time(168)"
                                        ><i class="far fa-clock"></i>
                                        Duration: one week
                                      </a>
                                    </small>
                                  </b-col>
                                </b-row>
                                <b-row>
                                  <b-col>
                                    <p class="" v-if="form.start_date">
                                      The hackathon will start on:
                                      {{ form.start_date.replace("T", " ") }}
                                    </p>
                                  </b-col>
                                </b-row>
                              </time-line-item>
                              <!-- Team Pitches -->
                              <time-line-item badge-type="primary" dot-only>
                                <b-row>
                                  <b-col>
                                    <b-row>
                                      <b-col sm="auto">
                                        <h4
                                          class="mt--1 mb-0"
                                          :class="{
                                            strike: !form.has_pitches,
                                          }"
                                        >
                                          Team Pitches
                                        </h4>
                                      </b-col>
                                      <b-col class="pl-0 mt--1">
                                        <b-form-checkbox
                                          v-model="form.has_pitches"
                                        ></b-form-checkbox>
                                      </b-col>
                                      <b-col
                                        class="text-right"
                                        v-if="
                                          form.has_pitches &&
                                          phase_allowed_as_next('TEAM_PITCHES')
                                        "
                                        sm="auto"
                                      >
                                        <base-button
                                          type="primary"
                                          :pill="true"
                                          size="sm"
                                          @click="
                                            show_phase_change_confirmation_modal(
                                              'TEAM_PITCHES'
                                            )
                                          "
                                          >Activate
                                        </base-button>
                                      </b-col>
                                      <b-col
                                        class="text-right"
                                        v-if="
                                          hackathon.phase == 'TEAM_PITCHES' &&
                                          form.has_pitches
                                        "
                                        sm="auto"
                                      >
                                        <badge
                                          :rounded="true"
                                          size="md"
                                          type="primary"
                                        >
                                          Active
                                        </badge>
                                      </b-col>
                                    </b-row>
                                    <b-row v-if="form.has_pitches">
                                      <b-col>
                                        <p class="">Teams pitch their work.</p>
                                      </b-col>
                                    </b-row>
                                    <b-row v-else>
                                      <b-col>
                                        <p class="">
                                          This hackathon does not have any team
                                          pitching their work.
                                        </p>
                                      </b-col>
                                    </b-row>
                                  </b-col>
                                </b-row>
                              </time-line-item>
                              <!-- Hackathon Ends -->
                              <time-line-item badge-type="primary" dot-only>
                                <b-row>
                                  <b-col>
                                    <h4 class="mt--1 mb-0">Hackathon Ends</h4>
                                    <p class="">
                                      The hackathon ends it's normally good to
                                      end with a call or talk. Check box for
                                      call.
                                    </p>
                                  </b-col>
                                </b-row>
                                <b-row>
                                  <b-col>
                                    <b-form-checkbox
                                      v-model="form.has_closing_session"
                                    >
                                      <span
                                        class=""
                                        :class="{
                                          strike: !form.has_closing_session,
                                        }"
                                      >
                                        Add a hackathon closing talk/call to the
                                        program.
                                      </span>
                                    </b-form-checkbox>
                                  </b-col>
                                </b-row>
                              </time-line-item>
                              <!-- Judging Begins -->
                              <time-line-item badge-type="primary" dot-only>
                                <b-row>
                                  <b-col>
                                    <b-row>
                                      <b-col sm="auto">
                                        <h4
                                          class="mt--1 mb-0"
                                          :class="{
                                            strike: !form.has_judges,
                                          }"
                                        >
                                          Judging
                                        </h4>
                                      </b-col>
                                      <b-col class="pl-0 mt--1">
                                        <b-form-checkbox
                                          v-model="form.has_judges"
                                        ></b-form-checkbox>
                                      </b-col>
                                      <b-col
                                        class="text-right"
                                        v-if="
                                          form.has_judges &&
                                          phase_allowed_as_next('JUDGING')
                                        "
                                        sm="auto"
                                      >
                                        <base-button
                                          type="primary"
                                          :pill="true"
                                          size="sm"
                                          @click="
                                            show_phase_change_confirmation_modal(
                                              'JUDGING'
                                            )
                                          "
                                          >Activate
                                        </base-button>
                                      </b-col>
                                      <b-col
                                        class="text-right"
                                        v-if="
                                          hackathon.phase == 'JUDGING' &&
                                          form.has_judges
                                        "
                                        sm="auto"
                                      >
                                        <badge
                                          :rounded="true"
                                          size="md"
                                          type="primary"
                                        >
                                          Active
                                        </badge>
                                      </b-col>
                                    </b-row>

                                    <b-row v-if="form.has_judges">
                                      <b-col>
                                        <p class="">
                                          The judges view and adjudicate each
                                          team's work.
                                        </p>
                                        <br />
                                        <router-link
                                          :to="{
                                            path: `/hackathon/${hackathon.id}/judging-criteria`,
                                          }"
                                          v-if="hackathon.id"
                                        >
                                          <small>Edit judging criteria</small>
                                        </router-link>
                                        <br />
                                        <router-link
                                          :to="{
                                            path: `/hackathon/${hackathon.id}/judges`,
                                          }"
                                          v-if="hackathon.id"
                                        >
                                          <small>Invite Judges</small>
                                        </router-link>
                                      </b-col>
                                    </b-row>
                                    <b-row v-else>
                                      <b-col>
                                        <p class="">
                                          This hackathon doesn't have any judges
                                          , judging or prizes.
                                        </p>
                                      </b-col>
                                    </b-row>
                                  </b-col>
                                </b-row>
                              </time-line-item>
                              <!-- Winner's announcement -->
                              <time-line-item badge-type="primary" dot-only>
                                <b-row>
                                  <b-col>
                                    <h4
                                      class="mt--1 mb-0"
                                      :class="{
                                        strike: !form.has_judges,
                                      }"
                                    >
                                      Winner announcment
                                    </h4>
                                    <p class="" v-if="form.has_judges">
                                      The winners will be announced. This can be
                                      done in many ways: e.g. in a session, call
                                      or on a video release.
                                    </p>
                                    <p class="" v-else>
                                      This hackathon doesn't have any judging so
                                      no winners can be announced.
                                    </p>
                                  </b-col>
                                </b-row>
                                <b-row>
                                  <b-col>
                                    <b-form-checkbox
                                      v-model="form.has_prizes"
                                      :disabled="!form.has_judges"
                                    >
                                      <span
                                        class=""
                                        :class="{
                                          strike: !form.has_prizes,
                                        }"
                                      >
                                        Prizes will be given to top teams.

                                        {{
                                          !form.has_judges
                                            ? "(Judging is required for prizes)"
                                            : ""
                                        }}
                                      </span>
                                    </b-form-checkbox>
                                  </b-col>
                                </b-row>
                                <b-row class="mt-4">
                                  <b-col>
                                    <h4
                                      class="mb-0"
                                      v-if="hackathon.id && form.has_prizes"
                                    >
                                      Prizes
                                    </h4>
                                  </b-col>
                                </b-row>
                                <b-row>
                                  <b-col>
                                    <PrizesWidget
                                      v-if="hackathon.id && form.has_prizes"
                                      :hackathon_id="hackathon.id"
                                      :has_heading="false"
                                    />
                                  </b-col>
                                </b-row>
                              </time-line-item>
                            </time-line>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col v-if="!hackathon.phase == 'COMPLETE'">
                            <base-button
                              type="primary"
                              :pill="true"
                              size="lg"
                              @click="
                                show_phase_change_confirmation_modal('COMPLETE')
                              "
                              >End Hackathon
                            </base-button>
                          </b-col>
                          <b-col v-if="hackathon.phase == 'COMPLETE'">
                            <badge :rounded="true" size="xl" type="primary">
                              Hackathon has been ENDED
                            </badge>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col class="text-right">
                            <base-button
                              type="primary"
                              :pill="true"
                              native-type="submit"
                              class="my-4 btn-success btn-right fixed-update-button"
                              :disabled="form.save.loading"
                              :loading="form.save.loading"
                              :success="form.save.success"
                              id="floatingUpdateButton"
                              v-if="
                                hackathon.phase != 'COMPLETE' &&
                                hackathon.id != null
                              "
                            >
                              {{ "Update" }}
                            </base-button>
                            <base-button
                              type="primary"
                              :pill="true"
                              native-type="submit"
                              class="my-4 btn-success btn-right"
                              :disabled="form.save.loading"
                              :loading="form.save.loading"
                              :success="form.save.success"
                              id="updateButton"
                              v-if="hackathon.phase != 'COMPLETE'"
                            >
                              {{ hackathon.id == null ? "Save" : "Update" }}
                            </base-button>
                          </b-col>
                        </b-row>
                      </b-form>
                    </validation-observer>
                  </b-col>
                </b-row>
              </card>
            </b-col>
            <b-col v-if="!hackathon.id">
              <GenerateHackathonCard @ai_create_hackathon="handle_ai_create" />
            </b-col>
          </b-row>
        </b-container>
      </b-col>
    </b-row>
  </div>
</template>

<script>
// Components
import { TimeLine, TimeLineItem } from "@/components";
import BaseInput from "@/components/Inputs/BaseInput.vue";
import HtmlEditor from "@/components/Inputs/HtmlEditor";
import { Select, Option } from "element-ui";
import GoalsWidget from "@/views/Components/Hackathon/Goals/GoalsWidget.vue";
import PrizesWidget from "@/views/Components/Hackathon/Prizes/PrizesWidget.vue";
import GenerateHackathonCard from "@/views/Components/Hackathon/Manage/GenerateHackathonCard.vue";

// Queries
import {
  GET_HACKATHON_HACKATHON,
  GET_ORGANIZATION_ASSOCIATED,
  GET_ALL_HACKATHON_ALLOWED_PARTICIPANT_OPTIONS,
} from "@/graphql/queries";

// Mutations
import {
  CREATE_HACKATHON,
  UPDATE_HACKATHON,
  BATCH_CREATE_HACKATHON_JUDGING_CRITERIA,
  BATCH_CREATE_HACKATHON_GOAL,
} from "@/graphql/mutations";

function getTimezoneOffset() {
  function z(n) {
    return (n < 10 ? "0" : "") + n;
  }
  var offset = new Date().getTimezoneOffset();
  var sign = offset < 0 ? "+" : "-";
  offset = Math.abs(offset);
  return sign + z((offset / 60) | 0) + ":" + z(offset % 60);
}

export default {
  name: "HackathonTimeline",
  components: {
    GoalsWidget,
    PrizesWidget,
    GenerateHackathonCard,
    TimeLine,
    TimeLineItem,
    BaseInput,
    HtmlEditor,
    [Select.name]: Select,
    [Option.name]: Option,
  },
  apollo: {
    get_all_associated_organizations: {
      query: GET_ORGANIZATION_ASSOCIATED,
      result(res) {
        this.form.organization.options = [];
        let organizations = graph_utils.flatten_objects_recursive(
          graph_utils.apollo_to_obj_recursive(res.data.allOrganization)
        );
        if (organizations.length > 0) {
          organizations.forEach((el) => {
            if (el.name) {
              this.form.organization.options.push({
                id: el.id,
                name: el.name,
              });
            }
          });
        }
        if (organizations.length == 1) {
          this.form.organization.value = this.form.organization.options[0].id;
        }
      },
      error(errors) {
        console.log("Smart Query Error Handler: " + this.$options.name); // Check out https://stackoverflow.com/questions/66782888/how-do-i-consume-errors-in-my-vue-graphql-component-and-let-other-errors-be-hand
        console.log(errors.graphQLErrors);
        return false;
      },

      update(data) {
        this.apollo_data.get_all_associated_organizations = data;
      },
    },

    get_hackathon: {
      query: GET_HACKATHON_HACKATHON,
      result(data) {
        if (!data.data) {
          this.$router.push({
            name: "HackathonTimeline",
          });
          // this.$notify({
          //     message: `Something went wrong`,
          //     timeout: 3000,
          //     icon: "ni ni-check-bold",
          //     type: "danger",
          // });

          return;
        }

        const timezoneOffset = new Date().getTimezoneOffset() * 60000;
        this.form.start_date = new Date(
          new Date(data.data.hackathonHackathon.startDatetime).getTime() -
            timezoneOffset
        )
          .toISOString()
          .substring(0, 19);
        this.form.end_date = new Date(
          new Date(data.data.hackathonHackathon.endDatetime).getTime() -
            timezoneOffset
        )
          .toISOString()
          .substring(0, 19);

        this.form.hackathon_name = data.data.hackathonHackathon.name;
        this.form.hackathon_short_desc =
          data.data.hackathonHackathon.description;
        this.form.hackathon_long_desc =
          data.data.hackathonHackathon.detailDescription;
        this.form.max_team_size = data.data.hackathonHackathon.maxTeamSize;
        this.form.allowed_participants.value =
          data.data.hackathonHackathon.allowedParticipants;

        this.form.has_judges = data.data.hackathonHackathon.hasJudges;
        this.form.has_prizes = data.data.hackathonHackathon.hasPrizes;
        this.form.has_mentors = data.data.hackathonHackathon.hasMentors;
        this.form.has_pitches = data.data.hackathonHackathon.hasPitches;
        this.form.management_team_visibile =
          data.data.hackathonHackathon.managementTeamVisible;
        this.form.has_volunteers = data.data.hackathonHackathon.hasVolunteers;
        this.form.has_kickoff_session =
          data.data.hackathonHackathon.hasKickoffSession;
        this.form.has_closing_session =
          data.data.hackathonHackathon.hasClosingSession;
        this.form.has_goals = data.data.hackathonHackathon.hasGoals;

        this.form.ideas_require_approval =
          data.data.hackathonHackathon.ideasRequireApproval;

        if (data.data.hackathonHackathon.organization) {
          this.form.organization.value =
            data.data.hackathonHackathon.organization.id;
        }

        this.hackathon.phase = data.data.hackathonHackathon.phase;
        this.hackathon.phase_display =
          data.data.hackathonHackathon.phaseDisplay;
        this.hackathon.status = data.data.hackathonHackathon.status;
        this.hackathon.status_display =
          data.data.hackathonHackathon.statusDisplay;
      },
      update(data) {
        this.apollo_data.get_hackathon = data;
      },
      error(errors) {
        console.log("Smart Query Error Handler: " + this.$options.name); // Check out https://stackoverflow.com/questions/66782888/how-do-i-consume-errors-in-my-vue-graphql-component-and-let-other-errors-be-hand
        console.log(errors.graphQLErrors);
        return false;
      },
      variables() {
        return {
          id: this.hackathon.id,
        };
      },
      skip: true,
    },
  },
  data() {
    return {
      apollo_data: {
        get_hackathon: null,
        get_all_associated_organizations: null,
      },

      hackathon: {
        id: null,
        phase_display: null,
        phase: null,
        status: null,
        status_display: null,
      },

      phase_change_confirmation_modal: {
        new_phase: "",
      },

      form: {
        hackathon_name: null,
        hackathon_short_desc: null,
        hackathon_long_desc: "",
        start_date: null,
        end_date: null,
        organization: {
          value: null,
          options: [
            {
              id: "xxxxx",
              name: "Test OrgName",
            },
          ],
        },
        allowed_participants: {
          value: "PUBLIC",
          options: [
            {
              id: "PUBLIC",
              name: "Public (Anyone)",
            },

            {
              id: "INVITE_ONLY",
              name: "Invite Only",
            },
          ],
        },
        max_team_size: 5,
        save: {
          loading: false,
          success: false,
        },

        // Boolean Settings
        management_team_visibile: false,
        has_mentors: true,
        has_volunteers: true,
        has_prizes: true,
        has_judges: true,
        has_pitches: true,
        has_closing_session: true,
        has_kickoff_session: true,
        has_goals: true,
        ideas_require_approval: false,
      },

      goals_widget: {
        refresh: false,
      },
    };
  },
  methods: {
    get_future_date(days) {
      // sets the number of future days from date
      let date = new Date();
      date.setDate(date.getDate() + days);
      date.setHours(10, 0, 0, 0);
      return date;
    },

    set_event_future_date_time(event_date_time, future_days) {
      this.form[event_date_time] = this.get_future_date(future_days)
        .toISOString()
        .substring(0, 19);
    },

    set_event_end_date_time(hours) {
      let date = new Date(this.form.start_date);
      date.addHours(hours);
      date.subtractHours(date.getTimezoneOffset() / 60);
      this.form.end_date = date.toISOString().substring(0, 19);
    },

    // #region AI functions
    // Handles the auto creation of a hackathon based off of the ai data.
    handle_ai_create(data) {
      console.log(data);
      this.form.hackathon_name = data.hackathon_name;
      this.form.hackathon_short_desc = data.hackathon_short_description;
      this.form.hackathon_long_desc = data.hackathon_detail_description;
      this.set_event_future_date_time("start_date", 30);
      this.set_event_future_date_time("end_date", 48);

      this.submit_form().then((res) => {
        if (res) {
          // this is where we batch create criteria and goals.
          this.batch_create_hackathon_judging_criterias(
            data.hackathon_judging_criteria
          );
          this.batch_create_hackathon_goals(data.hackathon_goals);
          // after we're done, we need to refetch the goals.
        }
      });
    },

    batch_create_hackathon_judging_criterias(criterias) {
      let create_criteria = [];
      if (criterias.length > 0) {
        criterias.forEach((el) => {
          create_criteria.push({
            hackathon: this.hackathon.id,
            name: el.criteria,
            maxScore: el.max_score,
            type: el.type == "sliding_scale" ? "SCORE" : "BINARY",
          });
        });
        this.$apollo
          .mutate({
            mutation: BATCH_CREATE_HACKATHON_JUDGING_CRITERIA,
            variables: {
              criteria_list: create_criteria,
            },
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    batch_create_hackathon_goals(goals) {
      let create_goals = [];
      if (goals.length > 0) {
        goals.forEach((el) => {
          create_goals.push({
            hackathon: this.hackathon.id,
            name: el.goal,
            priority: el.priority,
          });
        });
        this.$apollo
          .mutate({
            mutation: BATCH_CREATE_HACKATHON_GOAL,
            variables: {
              goal_list: create_goals,
            },
          })
          .then((res) => {
            setTimeout(() => {
              this.goals_widget.refresh = true;
              setTimeout(() => {
                this.goals_widget.refresh = false;
              }, 10);
            }, 3000);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    // #endregion

    // Form Functions
    submit_form() {
      if (this.hackathon.id) {
        return this.update_hackathon();
      } else {
        return this.create_hackathon();
      }
    },

    create_hackathon() {
      this.form.save.loading = true;
      return this.$apollo
        .mutate({
          mutation: CREATE_HACKATHON,
          variables: {
            name: this.form.hackathon_name,
            description: this.form.hackathon_short_desc,
            detail_description: this.form.hackathon_long_desc,
            allowed_participants: this.form.allowed_participants.value,
            max_team_size: Number(this.form.max_team_size),
            start_date_time: this.form.start_date + getTimezoneOffset(),
            end_date_time: this.form.end_date + getTimezoneOffset(),
            organization: this.form.organization.value,
            has_judges: this.form.has_judges,
            has_prizes: this.form.has_prizes,
            has_mentors: this.form.has_mentors,
            has_volunteers: this.form.has_volunteers,
            has_pitches: this.form.has_pitches,
            has_kickoff_session: this.form.has_kickoff_session,
            has_closing_session: this.form.has_closing_session,
            management_team_visible: this.form.management_team_visibile,
            has_goals: this.form.has_goals,
            ideas_require_approval: this.form.ideas_require_approval,
          },
        })
        .then((res) => {
          if ("data" in res) {
            this.hackathon.id = res.data.hackathonCreateHackathon.hackathon.id;
            this.$notify({
              message: `'${this.form.hackathon_name}' has been created.`,
              timeout: 3000,
              icon: "ni ni-check-bold",
              type: "success",
            });
            this.form.save.success = true;
            setTimeout(() => {
              this.form.save.success = false;
            }, 3000);

            setTimeout(() => {
              this.$router.push({
                name: "HackathonTimeline",
                params: {
                  hackathon_id: this.hackathon.id,
                },
              });
            }, 3000);
            this.activate_hackathon();
          } else {
            this.$notify({
              message: `Something went wrong: Could not create '${this.form.hackathon_name}'`,
              timeout: 5000,
              icon: "ni ni-cross-bold",
              type: "danger",
            });
          }
          this.form.save.loading = false;
          setTimeout(() => {
            global_event_emitter.$emit("hackathon_sidebar_reload_required");
          }, 2000);
          return this.hackathon.id;
        })
        .catch((res) => {
          this.$notify({
            message: `Something went wrong: Could not create '${this.form.hackathon_name}'`,
            timeout: 5000,
            icon: "ni ni-cross-bold",
            type: "danger",
          });
          this.form.save.loading = false;
          return null;
        });
    },

    update_hackathon() {
      this.form.save.loading = true;
      return this.$apollo
        .mutate({
          mutation: UPDATE_HACKATHON,
          variables: {
            id: this.hackathon.id,
            name: this.form.hackathon_name,
            description: this.form.hackathon_short_desc,
            detail_description: this.form.hackathon_long_desc,
            allowed_participants: this.form.allowed_participants.value,
            max_team_size: Number(this.form.max_team_size),
            start_date_time: this.form.start_date + getTimezoneOffset(),
            end_date_time: this.form.end_date + getTimezoneOffset(),
            organization: this.form.organization.value,
            has_judges: this.form.has_judges,
            has_prizes: this.form.has_prizes,
            has_mentors: this.form.has_mentors,
            has_volunteers: this.form.has_volunteers,
            has_pitches: this.form.has_pitches,
            has_kickoff_session: this.form.has_kickoff_session,
            has_closing_session: this.form.has_closing_session,
            management_team_visible: this.form.management_team_visibile,
            has_goals: this.form.has_goals,
            ideas_require_approval: this.form.ideas_require_approval,
          },
        })
        .then((res) => {
          if ("data" in res) {
            this.$notify({
              message: `'${this.form.hackathon_name}' has been updated.`,
              timeout: 3000,
              icon: "ni ni-check-bold",
              type: "success",
            });
            this.form.save.success = true;
            setTimeout(() => {
              this.form.save.success = false;
            }, 3000);
          } else {
            this.$notify({
              message: `Something went wrong: Could not update '${this.form.hackathon_name}'`,
              timeout: 5000,
              icon: "ni ni-cross-bold",
              type: "danger",
            });
          }
          this.form.save.loading = false;
          setTimeout(() => {
            global_event_emitter.$emit("hackathon_sidebar_reload_required");
          }, 2000);

          this.activate_hackathon();
        })
        .catch((res) => {
          console.log(res);

          this.$notify({
            message: `Something went wrong: Could not update '${this.form.hackathon_name}'`,
            timeout: 5000,
            icon: "ni ni-cross-bold",
            type: "danger",
          });
          this.form.save.loading = false;
        });
    },

    get_hackathon_id() {
      if (
        this.$route.params.hackathon_id &&
        this.$route.params.hackathon_id != "new"
      ) {
        this.hackathon.id = this.$route.params.hackathon_id;
        if ("get_hackathon" in this.$apollo.queries) {
          graph_utils.enable_query(this.$apollo.queries.get_hackathon);
        }
      } else {
        this.hackathon.id = null;
        if ("get_hackathon" in this.$apollo.queries) {
          graph_utils.disable_query(this.$apollo.queries.get_hackathon);
        }
      }
    },

    show_phase_change_confirmation_modal(new_phase) {
      this.phase_change_confirmation_modal.new_phase = new_phase;
      this.$bvModal.show("phase_change_confirmation_modal");
    },

    handle_phase_change_confirmation_modal_confirm() {
      this.$apollo
        .mutate({
          mutation: UPDATE_HACKATHON,
          variables: {
            id: this.hackathon.id,
            phase: this.phase_change_confirmation_modal.new_phase,
          },
        })
        .then((res) => {
          if ("data" in res) {
            this.$notify({
              message: `The hackation phase was updated successfully for: ${this.form.hackathon_name}'`,
              timeout: 3000,
              icon: "ni ni-check-bold",
              type: "success",
            });

            this.$apollo.queries.get_hackathon.refetch();
            this.$bvModal.hide("phase_change_confirmation_modal");
          } else {
            this.$notify({
              message: `Something went wrong: Could not update '${this.form.hackathon_name}'`,
              timeout: 5000,
              icon: "ni ni-cross-bold",
              type: "danger",
            });
          }
        })
        .catch((res) => {
          console.log(res);

          this.$notify({
            message: `Something went wrong: Could not update '${this.form.hackathon_name}'`,
            timeout: 5000,
            icon: "ni ni-cross-bold",
            type: "danger",
          });
        });
    },

    phase_allowed_as_next(phase) {
      // Progressing the phases to the future.
      if (phase == "REGISTRATION_OPEN" && this.hackathon.phase == "PLANNING") {
        return true;
      }

      if (
        phase == "IDEA_SUBMISSION" &&
        this.hackathon.phase == "REGISTRATION_OPEN"
      ) {
        return true;
      }

      if (
        phase == "HACKING_STARTED" &&
        this.hackathon.phase == "IDEA_SUBMISSION"
      ) {
        return true;
      }
      if (
        phase == "HACKING_ENDED" &&
        this.hackathon.phase == "HACKING_STARTED"
      ) {
        return true;
      }
      if (
        phase == "TEAM_PITCHES" &&
        this.hackathon.phase == "HACKING_ENDED" &&
        this.form.has_pitches
      ) {
        return true;
      }
      if (
        phase == "JUDGING" &&
        ((this.hackathon.phase == "HACKING_ENDED" && !this.form.has_pitches) ||
          this.hackathon.phase == "TEAM_PITCHES")
      ) {
        return true;
      }

      if (
        phase == "COMPLETE" &&
        ((this.hackathon.phase == "HACKING_ENDED" &&
          !this.form.has_pitches &&
          !this.form.has_judges) ||
          (this.hackathon.phase == "TEAM_PITCHES" && !this.form.has_judges) ||
          (this.hackathon.phase == "JUDGING" && !this.form.has_pitches))
      ) {
        return true;
      }

      return false;
    },

    get_confirmation_message(phase) {
      if (phase == "REGISTRATION_OPEN") {
        return `You are about to release the ${this.form.hackathon_name} hackathon for registration.<br/><br/><strong>This action is irreversible.</strong><br/><br/>You will not be able to move back to this phase in the timeline.`;
      }
      if (phase == "IDEA_SUBMISSION") {
        return "You are about to progress your hackathon to the 'Idea Submission' phase.<br/><br/><strong>This action is irreversible.</strong><br/><br/>You will not be able to move back to this phase in the timeline.";
      }
      if (phase == "HACKING_STARTED") {
        return "You are about to start the 'Hacking' phase.<br/><br/><strong>This action is irreversible.</strong><br/><br/>You will not be able to move back to this phase in the timeline.";
      }

      if (phase == "HACKING_ENDED") {
        return "You are about to end the hacking. <br>Once the hacking has ended, teams can no longer make any submissions.<br/><br/><strong>This action is irreversible.</strong><br/><br/>You will not be able to move back to this phase in the timeline.";
      }
      if (phase == "TEAM_PITCHES") {
        return "You are about to start the 'Pitches' phase.<br/><br/><strong>This action is irreversible.</strong><br/><br/>You will not be able to move back to this phase in the timeline.";
      }
      if (phase == "JUDGING") {
        return "You are about to start the 'Judging' phase.<br/><br/><strong>This action is irreversible.</strong><br/><br/>You will not be able to move back to this phase in the timeline.";
      }
      if (phase == "COMPLETE") {
        return "You are about to END your hackathon.<br/><br/><strong>This action is irreversible.</strong><br/><br/>You will not be able to move back to this phase in the timeline.";
      }
    },

    activate_hackathon() {
      store.commit("setActiveHackathon", {
        id: this.hackathon.id,
        name: this.form.hackathon_name,
      });
      global_event_emitter.$emit("hackathon_sidebar_reload_required");
    },
    isInViewport(element) {
      const rect = element.getBoundingClientRect();
      return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <=
          (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <=
          (window.innerWidth || document.documentElement.clientWidth)
      );
    },
    handleScroll() {
      if (this.isInViewport(document.getElementById("updateButton"))) {
        document.getElementById("floatingUpdateButton").hidden = true;
      } else {
        document.getElementById("floatingUpdateButton").hidden = false;
      }
    },
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  mounted() {
    this.get_hackathon_id();
    this.handleScroll();
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  watch: {
    "$route.params.hackathon_id": {
      handler() {
        this.get_hackathon_id();
      },
      immediate: true,
    },
    "hackathon.id": {
      handler() {
        if ("get_hackathon" in this.$apollo.queries) {
          graph_utils.enable_query(this.$apollo.queries.get_hackathon);
        }
      },
      immediate: true,
    },
    "form.has_judges": {
      handler() {
        if (!this.form.has_judges) {
          this.form.has_prizes = false;
        }
      },
      immediate: true,
    },
  },
};
Date.prototype.addHours = function (h) {
  this.setHours(this.getHours() + h);
  return this;
};
Date.prototype.subtractHours = function (h) {
  this.setHours(this.getHours() - h);
  return this;
};
</script>

<style>
.el-input__inner {
  border-radius: 10rem;
}

.collapsed > .when-opened,
:not(.collapsed) > .when-closed {
  display: none;
}

.strike {
  text-decoration: line-through;
}

.fixed-update-button {
  position: fixed;
  bottom: 10%;
  z-index: 99;
}
</style>
