var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{attrs:{"id":"phase_change_confirmation_modal","title":"Confirm","size":"sm","no-close-on-backdrop":"","no-close-on-esc":"","hide-footer":""}},[_c('div',{staticClass:"text-center"},[_c('h1',[_vm._v("Are you sure?")]),_c('p',{domProps:{"innerHTML":_vm._s(
          _vm.get_confirmation_message(_vm.phase_change_confirmation_modal.new_phase)
        )}}),_c('base-button',{staticClass:"my-4 btn-success",attrs:{"type":"danger","pill":true},on:{"click":function($event){return _vm.handle_phase_change_confirmation_modal_confirm(
            _vm.phase_change_confirmation_modal.new_phase
          )}}},[_vm._v("Confirm ")]),_c('base-button',{staticClass:"my-4 btn-success",attrs:{"type":"primary","pill":true},on:{"click":function($event){return _vm.$bvModal.hide('phase_change_confirmation_modal')}}},[_vm._v("Cancel ")])],1)]),_c('b-row',[_c('b-col',[_c('div',{staticClass:"header pb-6 d-flex align-items-center"},[_c('span',{staticClass:"mask bg-white opacity-8"}),_c('b-container',{staticClass:"d-flex",attrs:{"fluid":""}},[_c('b-row',[_c('b-col',{staticClass:"mt-2",attrs:{"sm":"12","md":"auto"}},[(!_vm.hackathon.id)?_c('h1',{staticClass:"text-primary"},[_vm._v(" Create Hackathon ")]):_c('h1',{staticClass:"text-primary"},[_vm._v(" "+_vm._s(_vm.form.hackathon_name)+" ")])]),(_vm.hackathon.phase_display)?_c('b-col',[_c('badge',{staticClass:"mt-3",attrs:{"rounded":true,"size":"md","type":"info"}},[_vm._v(" "+_vm._s(_vm.hackathon.phase_display)+" ")])],1):_vm._e(),(
                _vm.hackathon.status_display &&
                _vm.hackathon.status_display != _vm.hackathon.phase_display
              )?_c('b-col',[_c('badge',{staticClass:"mt-3",attrs:{"rounded":true,"size":"md","type":"danger"}},[_vm._v(" "+_vm._s(_vm.hackathon.status_display)+" ")])],1):_vm._e()],1)],1)],1)])],1),_c('b-row',[_c('b-col',[_c('b-container',{staticClass:"mt--6",attrs:{"fluid":""}},[_c('b-row',[_c('b-col',{attrs:{"sm":"12","xl":"8"}},[_c('card',{attrs:{"header-classes":"bg-transparent"}},[_c('b-row',[_c('b-col',[_c('validation-observer',{ref:"formValidator",scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var handleSubmit = ref.handleSubmit;
return [_c('b-form',{attrs:{"role":"form"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit_form)}}},[_c('base-input',{staticClass:"mb-3",attrs:{"pill":true,"label":"Hackathon Name*","name":"Hackathon Name","rules":{ required: true },"placeholder":"Create something hackahton","disabled":_vm.$apollo.loading},model:{value:(_vm.form.hackathon_name),callback:function ($$v) {_vm.$set(_vm.form, "hackathon_name", $$v)},expression:"form.hackathon_name"}}),_c('base-input',{staticClass:"mb-3",attrs:{"pill":true,"label":"Hackathon short description* ","name":"Hackathon Description","placeholder":"A one liner that describes your hackathon","disabled":_vm.$apollo.loading,"rules":{
                          required: true,
                        }},model:{value:(_vm.form.hackathon_short_desc),callback:function ($$v) {_vm.$set(_vm.form, "hackathon_short_desc", $$v)},expression:"form.hackathon_short_desc"}}),_c('html-editor',{staticClass:"mb-3",attrs:{"pill":true,"label":"Hackathon detail description","name":"Detailed Description","disabled":_vm.$apollo.loading},model:{value:(_vm.form.hackathon_long_desc),callback:function ($$v) {_vm.$set(_vm.form, "hackathon_long_desc", $$v)},expression:"form.hackathon_long_desc"}}),(_vm.form.organization.options.length > 1)?_c('b-row',[_c('b-col',[_c('base-input',{attrs:{"label":"Organization","rules":{
                              required: true,
                            },"pill":true,"name":"Organization","disabled":_vm.$apollo.loading}},[_c('el-select',{staticClass:"form",attrs:{"filterable":true,"disabled":_vm.$apollo.loading},model:{value:(_vm.form.organization.value),callback:function ($$v) {_vm.$set(_vm.form.organization, "value", $$v)},expression:"form.organization.value"}},_vm._l((_vm.form.organization.options),function(option){return _c('el-option',{key:option.id,attrs:{"label":option.name || 'Unnamed Organization',"value":option.id}})}),1)],1)],1)],1):_vm._e(),_c('b-row',[_c('b-col',[_c('a',{directives:[{name:"b-toggle",rawName:"v-b-toggle"}],attrs:{"href":"#optionalSettings"},on:{"click":function($event){$event.preventDefault();}}},[_vm._v("Optional Settings "),_c('span',{staticClass:"when-opened"},[_c('i',{staticClass:"fas fa-chevron-up"})]),_c('span',{staticClass:"when-closed"},[_c('i',{staticClass:"fas fa-chevron-down"})])]),_c('b-collapse',{attrs:{"id":"optionalSettings","role":"tabpanel"}},[(_vm.form.organization.options.length <= 1)?_c('b-row',[_c('b-col',[_c('base-input',{attrs:{"label":"Organization","rules":{
                                    required: true,
                                  },"pill":true,"name":"Organization","disabled":_vm.$apollo.loading}},[_c('el-select',{staticClass:"form",attrs:{"filterable":true,"disabled":_vm.$apollo.loading},model:{value:(_vm.form.organization.value),callback:function ($$v) {_vm.$set(_vm.form.organization, "value", $$v)},expression:"form.organization.value"}},_vm._l((_vm.form.organization
                                        .options),function(option){return _c('el-option',{key:option.id,attrs:{"label":option.name,"value":option.id}})}),1)],1)],1)],1):_vm._e(),_c('b-row',[_c('b-col',[_c('b-form-checkbox',{model:{value:(_vm.form.management_team_visibile),callback:function ($$v) {_vm.$set(_vm.form, "management_team_visibile", $$v)},expression:"form.management_team_visibile"}},[_c('span',{},[_vm._v(" The management team names and contact information is available to participants. ")])])],1)],1)],1)],1)],1),_c('b-row',{staticClass:"mt-5"},[_c('b-col',[_c('b-row',[_c('b-col',{attrs:{"sm":"auto"}},[_c('h3',{staticClass:"mb-0",class:{
                                  strike: !_vm.form.has_goals,
                                }},[_vm._v(" Hackathon Goals ")])]),_c('b-col',{staticClass:"pl-0"},[_c('b-form-checkbox',{model:{value:(_vm.form.has_goals),callback:function ($$v) {_vm.$set(_vm.form, "has_goals", $$v)},expression:"form.has_goals"}})],1)],1)],1)],1),_c('b-row',[_c('b-col',[_c('p',{},[_vm._v(" To organize impactful hackathons, you need goals. Having clear goals of what you want to achieve with your hackathon will guide participants, mentors and judges. When teams submit their work, they will select the goals that their solutions contributes to. We recommend having a minimum of 3 goals. ")])])],1),_c('b-row',[_c('b-col',[(_vm.hackathon.id && _vm.form.has_goals)?_c('GoalsWidget',{attrs:{"hackathon_id":_vm.hackathon.id,"has_heading":false,"refresh":_vm.goals_widget.refresh}}):_vm._e()],1)],1),_c('b-row',{staticClass:"mt-5"},[_c('b-col',[_c('h3',[_vm._v("Your Hackathon Team")])])],1),_c('b-row',[_c('b-col',[_c('p',{},[_vm._v(" When running a hackathon, it's always better to do it with a team. Each hackathon team can consist of \"Co-organizers\", \"Hackathon Heroes\" and \"Team Mentors\". ")])])],1),_c('b-row',{staticClass:"mt-3"},[_c('b-col',[_c('h4',{staticClass:"mb-0"},[_vm._v("Co-organizers")])])],1),_c('b-row',[_c('b-col',[_c('p',{},[_vm._v(" Co-organizers are people who form part of the \"Management\" team of the hackathon. Members of the hackathon organizing team can manage attributes of the hackathon. ")]),(_vm.hackathon.id)?_c('p',{},[_vm._v(" To manage your organizing team, click "),_c('router-link',{attrs:{"to":{
                                path: ("/hackathon/" + (_vm.hackathon.id) + "/organizing-team"),
                              }}},[_vm._v("here")]),_vm._v(". ")],1):_vm._e()])],1),_c('b-row',{staticClass:"mt-3"},[_c('b-col',[_c('b-row',[_c('b-col',{attrs:{"sm":"auto"}},[_c('h4',{staticClass:"mb-0",class:{
                                  strike: !_vm.form.has_volunteers,
                                }},[_vm._v(" Hackathon Heroes ")])]),_c('b-col',{staticClass:"pl-0"},[_c('b-form-checkbox',{model:{value:(_vm.form.has_volunteers),callback:function ($$v) {_vm.$set(_vm.form, "has_volunteers", $$v)},expression:"form.has_volunteers"}})],1)],1)],1)],1),_c('b-row',[_c('b-col',[_c('p',{},[_vm._v(" Hackathon Heroes are people with a \"can-do\" attitude that supports the hackathon. They help teams, answer questions, listen to ideas and provide feedback. ")]),(_vm.hackathon.id && _vm.form.has_volunteers)?_c('p',{},[_vm._v(" To manage your hackathon heroes, click "),_c('router-link',{attrs:{"to":{
                                path: ("/hackathon/" + (_vm.hackathon.id) + "/heroes"),
                              }}},[_vm._v("here")]),_vm._v(". ")],1):_vm._e()])],1),_c('b-row',{staticClass:"mt-3"},[_c('b-col',[_c('b-row',[_c('b-col',{attrs:{"sm":"auto"}},[_c('h4',{staticClass:"mb-0",class:{
                                  strike: !_vm.form.has_mentors,
                                }},[_vm._v(" Mentors ")])]),_c('b-col',{staticClass:"pl-0"},[_c('b-form-checkbox',{model:{value:(_vm.form.has_mentors),callback:function ($$v) {_vm.$set(_vm.form, "has_mentors", $$v)},expression:"form.has_mentors"}})],1)],1)],1)],1),_c('b-row',[_c('b-col',[_c('p',{},[_vm._v(" Mentors are people that will guide, challenge and engage with teams. The mentors are there to help the teams flesh out their ideas and ask difficult questions. Normally Mentors \"roam\" the hackathon, joining virtual or physical tables to engage with the teams. ")]),(_vm.hackathon.id && _vm.form.has_mentors)?_c('p',{},[_vm._v(" To manage your mentors, click "),_c('router-link',{attrs:{"to":{
                                path: ("/hackathon/" + (_vm.hackathon.id) + "/organizing-team"),
                              }}},[_vm._v("here")]),_vm._v(". ")],1):_vm._e()])],1),_c('b-row',{staticClass:"mt-5"},[_c('b-col',[_c('b-row',[_c('b-col',[_c('h3',[_vm._v("Timeline")])])],1),_c('time-line',{attrs:{"type":"one-side"}},[_c('time-line-item',{attrs:{"badge-type":"primary","dot-only":""}},[_c('b-row',[_c('b-col',[_c('b-row',[_c('b-col',{attrs:{"sm":"auto"}},[_c('h4',{staticClass:"mt--1 mb-0"},[_vm._v(" Registrations open ")])]),(
                                        _vm.phase_allowed_as_next(
                                          'REGISTRATION_OPEN'
                                        )
                                      )?_c('b-col',{staticClass:"text-right"},[_c('base-button',{attrs:{"type":"primary","pill":true,"size":"sm"},on:{"click":function($event){return _vm.show_phase_change_confirmation_modal(
                                            'REGISTRATION_OPEN'
                                          )}}},[_vm._v("Activate ")])],1):_vm._e(),(
                                        _vm.hackathon.phase == 'REGISTRATION_OPEN'
                                      )?_c('b-col',{staticClass:"text-right",attrs:{"sm":"auto"}},[_c('badge',{attrs:{"rounded":true,"size":"md","type":"primary"}},[_vm._v(" Active ")])],1):_vm._e()],1),_c('p',{},[_vm._v(" This is when registrations open for the hackathon ")])],1)],1),_c('b-row',[_c('b-col',{attrs:{"sm":"12","md":"6"}},[_c('base-input',{attrs:{"label":"Who can participate?","rules":{
                                      required: true,
                                    },"pill":true,"name":"Particpants*","disabled":_vm.$apollo.loading}},[_c('el-select',{staticClass:"form",attrs:{"rules":{
                                        required: true,
                                      },"default-first-option":true,"filterable":true},model:{value:(
                                        _vm.form.allowed_participants.value
                                      ),callback:function ($$v) {_vm.$set(_vm.form.allowed_participants, "value", $$v)},expression:"\n                                        form.allowed_participants.value\n                                      "}},_vm._l((_vm.form
                                          .allowed_participants.options),function(option){return _c('el-option',{key:option.id,attrs:{"label":option.name,"value":option.id}})}),1)],1)],1)],1)],1),_c('time-line-item',{attrs:{"badge-type":"primary","dot-only":""}},[_c('b-row',[_c('b-col',[_c('h4',{staticClass:"mt--1 mb-0"},[_vm._v(" Idea submission and Team formation ")]),_c('p',{},[_vm._v(" Ideas is where everything begins, in this phase, people will submit ideas and find a team to work with. "),_c('br'),_vm._v(" For the best results, teams of 3 to 5 people is recommended. ")])]),(
                                    _vm.phase_allowed_as_next('IDEA_SUBMISSION')
                                  )?_c('b-col',{staticClass:"text-right",attrs:{"sm":"auto"}},[_c('base-button',{attrs:{"type":"primary","pill":true,"size":"sm"},on:{"click":function($event){return _vm.show_phase_change_confirmation_modal(
                                        'IDEA_SUBMISSION'
                                      )}}},[_vm._v("Activate ")])],1):_vm._e(),(_vm.hackathon.phase == 'IDEA_SUBMISSION')?_c('b-col',{staticClass:"text-right",attrs:{"sm":"auto"}},[_c('badge',{attrs:{"rounded":true,"size":"md","type":"primary"}},[_vm._v(" Active ")])],1):_vm._e()],1),_c('b-row',[_c('b-col',[_c('b-form-checkbox',{model:{value:(_vm.form.ideas_require_approval),callback:function ($$v) {_vm.$set(_vm.form, "ideas_require_approval", $$v)},expression:"form.ideas_require_approval"}},[_c('span',{class:{
                                        strike: !_vm.form.ideas_require_approval,
                                      }},[_vm._v(" Ideas has to be approved before they become visible to participants. ")])])],1)],1),_c('b-row',[_c('b-col',{attrs:{"sm":"4"}},[_c('base-input',{attrs:{"label":"Maximum team size","rules":{
                                      required: true,
                                      numeric: true,
                                      max_value: 10,
                                    },"pill":true,"type":"number","name":"Max team size","disabled":_vm.$apollo.loading},model:{value:(_vm.form.max_team_size),callback:function ($$v) {_vm.$set(_vm.form, "max_team_size", $$v)},expression:"form.max_team_size"}})],1)],1)],1),_c('time-line-item',{attrs:{"badge-type":"primary","dot-only":""}},[_c('b-row',[_c('b-col',[_c('h4',{staticClass:"mt--1 mb-0"},[_vm._v("Hackathon Starts")]),_c('p',{},[_vm._v(" This is where your hackathon officially starts. An onboarding presentation of 15 minutes should be given. ")])])],1),_c('b-row',[_c('b-col',{attrs:{"sm":"12","md":"6"}},[_c('base-input',{attrs:{"pill":true,"type":"datetime-local","id":"start-datetime","label":"Start date*","rules":{
                                      required: true,
                                    },"disabled":_vm.$apollo.loading},model:{value:(_vm.form.start_date),callback:function ($$v) {_vm.$set(_vm.form, "start_date", $$v)},expression:"form.start_date"}})],1)],1),_c('b-row',[_c('b-col',[_c('small',{staticClass:"font-weight-bold mt--6 pb--6"},[_c('a',{attrs:{"href":"javascript:void(0);"},on:{"click":function($event){return _vm.set_event_future_date_time(
                                          'start_date',
                                          14
                                        )}}},[_c('i',{staticClass:"far fa-clock"}),_vm._v(" In ~ 2 weeks ")]),_vm._v("   "),_c('a',{attrs:{"href":"javascript:void(0);"},on:{"click":function($event){return _vm.set_event_future_date_time(
                                          'start_date',
                                          28
                                        )}}},[_c('i',{staticClass:"far fa-clock"}),_vm._v(" In ~ 4 weeks ")]),_vm._v("   "),_c('a',{attrs:{"href":"javascript:void(0);"},on:{"click":function($event){return _vm.set_event_future_date_time(
                                          'start_date',
                                          60
                                        )}}},[_c('i',{staticClass:"far fa-clock"}),_vm._v(" In ~ 2 months ")])])])],1),_c('b-row',[_c('b-col',[_c('b-form-checkbox',{model:{value:(_vm.form.has_kickoff_session),callback:function ($$v) {_vm.$set(_vm.form, "has_kickoff_session", $$v)},expression:"form.has_kickoff_session"}},[_c('span',{class:{
                                        strike: !_vm.form.has_kickoff_session,
                                      }},[_vm._v(" Add a hackathon kickoff talk/call to the program. ")])])],1)],1)],1),_c('time-line-item',{attrs:{"badge-type":"primary","dot-only":""}},[_c('b-row',[_c('b-col',[_c('h4',{staticClass:"mt--1 mb-0"},[_vm._v("Hacking Ends")]),_c('p',{},[_vm._v(" Choose when the participant's time for hacking ends. This will also be when submissions are due. ")])]),(
                                    _vm.phase_allowed_as_next('HACKING_ENDED')
                                  )?_c('b-col',{staticClass:"text-right",attrs:{"sm":"auto"}},[_c('base-button',{attrs:{"type":"primary","pill":true,"size":"sm"},on:{"click":function($event){return _vm.show_phase_change_confirmation_modal(
                                        'HACKING_ENDED'
                                      )}}},[_vm._v("Activate ")])],1):_vm._e(),(_vm.hackathon.phase == 'HACKING_ENDED')?_c('b-col',{staticClass:"text-right",attrs:{"sm":"auto"}},[_c('badge',{attrs:{"rounded":true,"size":"md","type":"primary"}},[_vm._v(" Active ")])],1):_vm._e()],1),_c('b-row',[_c('b-col',{attrs:{"sm":"12","md":"6"}},[_c('base-input',{attrs:{"pill":true,"type":"datetime-local","id":"start-datetime","label":"End date*","rules":{
                                      required: true,
                                    },"disabled":_vm.$apollo.loading},model:{value:(_vm.form.end_date),callback:function ($$v) {_vm.$set(_vm.form, "end_date", $$v)},expression:"form.end_date"}})],1)],1),_c('b-row',[_c('b-col',[(_vm.form.start_date)?_c('small',{staticClass:"font-weight-bold mt--6 pb--6"},[_c('a',{attrs:{"href":"javascript:void(0);"},on:{"click":function($event){return _vm.set_event_end_date_time(24)}}},[_c('i',{staticClass:"far fa-clock"}),_vm._v(" Duration: 24 hours ")]),_vm._v("   "),_c('a',{attrs:{"href":"javascript:void(0);"},on:{"click":function($event){return _vm.set_event_end_date_time(48)}}},[_c('i',{staticClass:"far fa-clock"}),_vm._v(" Duration: 2 days (48h) ")]),_vm._v("   "),_c('a',{attrs:{"href":"javascript:void(0);"},on:{"click":function($event){return _vm.set_event_end_date_time(168)}}},[_c('i',{staticClass:"far fa-clock"}),_vm._v(" Duration: one week ")])]):_vm._e()])],1),_c('b-row',[_c('b-col',[(_vm.form.start_date)?_c('p',{},[_vm._v(" The hackathon will start on: "+_vm._s(_vm.form.start_date.replace("T", " "))+" ")]):_vm._e()])],1)],1),_c('time-line-item',{attrs:{"badge-type":"primary","dot-only":""}},[_c('b-row',[_c('b-col',[_c('b-row',[_c('b-col',{attrs:{"sm":"auto"}},[_c('h4',{staticClass:"mt--1 mb-0",class:{
                                          strike: !_vm.form.has_pitches,
                                        }},[_vm._v(" Team Pitches ")])]),_c('b-col',{staticClass:"pl-0 mt--1"},[_c('b-form-checkbox',{model:{value:(_vm.form.has_pitches),callback:function ($$v) {_vm.$set(_vm.form, "has_pitches", $$v)},expression:"form.has_pitches"}})],1),(
                                        _vm.form.has_pitches &&
                                        _vm.phase_allowed_as_next('TEAM_PITCHES')
                                      )?_c('b-col',{staticClass:"text-right",attrs:{"sm":"auto"}},[_c('base-button',{attrs:{"type":"primary","pill":true,"size":"sm"},on:{"click":function($event){return _vm.show_phase_change_confirmation_modal(
                                            'TEAM_PITCHES'
                                          )}}},[_vm._v("Activate ")])],1):_vm._e(),(
                                        _vm.hackathon.phase == 'TEAM_PITCHES' &&
                                        _vm.form.has_pitches
                                      )?_c('b-col',{staticClass:"text-right",attrs:{"sm":"auto"}},[_c('badge',{attrs:{"rounded":true,"size":"md","type":"primary"}},[_vm._v(" Active ")])],1):_vm._e()],1),(_vm.form.has_pitches)?_c('b-row',[_c('b-col',[_c('p',{},[_vm._v("Teams pitch their work.")])])],1):_c('b-row',[_c('b-col',[_c('p',{},[_vm._v(" This hackathon does not have any team pitching their work. ")])])],1)],1)],1)],1),_c('time-line-item',{attrs:{"badge-type":"primary","dot-only":""}},[_c('b-row',[_c('b-col',[_c('h4',{staticClass:"mt--1 mb-0"},[_vm._v("Hackathon Ends")]),_c('p',{},[_vm._v(" The hackathon ends it's normally good to end with a call or talk. Check box for call. ")])])],1),_c('b-row',[_c('b-col',[_c('b-form-checkbox',{model:{value:(_vm.form.has_closing_session),callback:function ($$v) {_vm.$set(_vm.form, "has_closing_session", $$v)},expression:"form.has_closing_session"}},[_c('span',{class:{
                                        strike: !_vm.form.has_closing_session,
                                      }},[_vm._v(" Add a hackathon closing talk/call to the program. ")])])],1)],1)],1),_c('time-line-item',{attrs:{"badge-type":"primary","dot-only":""}},[_c('b-row',[_c('b-col',[_c('b-row',[_c('b-col',{attrs:{"sm":"auto"}},[_c('h4',{staticClass:"mt--1 mb-0",class:{
                                          strike: !_vm.form.has_judges,
                                        }},[_vm._v(" Judging ")])]),_c('b-col',{staticClass:"pl-0 mt--1"},[_c('b-form-checkbox',{model:{value:(_vm.form.has_judges),callback:function ($$v) {_vm.$set(_vm.form, "has_judges", $$v)},expression:"form.has_judges"}})],1),(
                                        _vm.form.has_judges &&
                                        _vm.phase_allowed_as_next('JUDGING')
                                      )?_c('b-col',{staticClass:"text-right",attrs:{"sm":"auto"}},[_c('base-button',{attrs:{"type":"primary","pill":true,"size":"sm"},on:{"click":function($event){return _vm.show_phase_change_confirmation_modal(
                                            'JUDGING'
                                          )}}},[_vm._v("Activate ")])],1):_vm._e(),(
                                        _vm.hackathon.phase == 'JUDGING' &&
                                        _vm.form.has_judges
                                      )?_c('b-col',{staticClass:"text-right",attrs:{"sm":"auto"}},[_c('badge',{attrs:{"rounded":true,"size":"md","type":"primary"}},[_vm._v(" Active ")])],1):_vm._e()],1),(_vm.form.has_judges)?_c('b-row',[_c('b-col',[_c('p',{},[_vm._v(" The judges view and adjudicate each team's work. ")]),_c('br'),(_vm.hackathon.id)?_c('router-link',{attrs:{"to":{
                                          path: ("/hackathon/" + (_vm.hackathon.id) + "/judging-criteria"),
                                        }}},[_c('small',[_vm._v("Edit judging criteria")])]):_vm._e(),_c('br'),(_vm.hackathon.id)?_c('router-link',{attrs:{"to":{
                                          path: ("/hackathon/" + (_vm.hackathon.id) + "/judges"),
                                        }}},[_c('small',[_vm._v("Invite Judges")])]):_vm._e()],1)],1):_c('b-row',[_c('b-col',[_c('p',{},[_vm._v(" This hackathon doesn't have any judges , judging or prizes. ")])])],1)],1)],1)],1),_c('time-line-item',{attrs:{"badge-type":"primary","dot-only":""}},[_c('b-row',[_c('b-col',[_c('h4',{staticClass:"mt--1 mb-0",class:{
                                      strike: !_vm.form.has_judges,
                                    }},[_vm._v(" Winner announcment ")]),(_vm.form.has_judges)?_c('p',{},[_vm._v(" The winners will be announced. This can be done in many ways: e.g. in a session, call or on a video release. ")]):_c('p',{},[_vm._v(" This hackathon doesn't have any judging so no winners can be announced. ")])])],1),_c('b-row',[_c('b-col',[_c('b-form-checkbox',{attrs:{"disabled":!_vm.form.has_judges},model:{value:(_vm.form.has_prizes),callback:function ($$v) {_vm.$set(_vm.form, "has_prizes", $$v)},expression:"form.has_prizes"}},[_c('span',{class:{
                                        strike: !_vm.form.has_prizes,
                                      }},[_vm._v(" Prizes will be given to top teams. "+_vm._s(!_vm.form.has_judges ? "(Judging is required for prizes)" : "")+" ")])])],1)],1),_c('b-row',{staticClass:"mt-4"},[_c('b-col',[(_vm.hackathon.id && _vm.form.has_prizes)?_c('h4',{staticClass:"mb-0"},[_vm._v(" Prizes ")]):_vm._e()])],1),_c('b-row',[_c('b-col',[(_vm.hackathon.id && _vm.form.has_prizes)?_c('PrizesWidget',{attrs:{"hackathon_id":_vm.hackathon.id,"has_heading":false}}):_vm._e()],1)],1)],1)],1)],1)],1),_c('b-row',[(!_vm.hackathon.phase == 'COMPLETE')?_c('b-col',[_c('base-button',{attrs:{"type":"primary","pill":true,"size":"lg"},on:{"click":function($event){return _vm.show_phase_change_confirmation_modal('COMPLETE')}}},[_vm._v("End Hackathon ")])],1):_vm._e(),(_vm.hackathon.phase == 'COMPLETE')?_c('b-col',[_c('badge',{attrs:{"rounded":true,"size":"xl","type":"primary"}},[_vm._v(" Hackathon has been ENDED ")])],1):_vm._e()],1),_c('b-row',[_c('b-col',{staticClass:"text-right"},[(
                              _vm.hackathon.phase != 'COMPLETE' &&
                              _vm.hackathon.id != null
                            )?_c('base-button',{staticClass:"my-4 btn-success btn-right fixed-update-button",attrs:{"type":"primary","pill":true,"native-type":"submit","disabled":_vm.form.save.loading,"loading":_vm.form.save.loading,"success":_vm.form.save.success,"id":"floatingUpdateButton"}},[_vm._v(" "+_vm._s("Update")+" ")]):_vm._e(),(_vm.hackathon.phase != 'COMPLETE')?_c('base-button',{staticClass:"my-4 btn-success btn-right",attrs:{"type":"primary","pill":true,"native-type":"submit","disabled":_vm.form.save.loading,"loading":_vm.form.save.loading,"success":_vm.form.save.success,"id":"updateButton"}},[_vm._v(" "+_vm._s(_vm.hackathon.id == null ? "Save" : "Update")+" ")]):_vm._e()],1)],1)],1)]}}])})],1)],1)],1)],1),(!_vm.hackathon.id)?_c('b-col',[_c('GenerateHackathonCard',{on:{"ai_create_hackathon":_vm.handle_ai_create}})],1):_vm._e()],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }